import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { OuterContainer } from "../style";
import SearchField from "../../components/fields/searchField";
import { DeleteOutlined, DisconnectOutlined } from '@ant-design/icons';

import TableComponent from "../../components/table";
import CustomePagination from "../../components/table/pagination";

import DrawerComponent from "../../components/drawer";
import ModalComponent from "../../components/modal";
import ConfirmModal from "../../components/modal/confirmModal";
import VehicleForm from "./vehicleForm";
import {
  deleteVehicleApi,
  getVehicleListApi,
  searchVehicleApi,
  unassignVehicleApi
} from "../../services/Api/collection";
import { message } from "antd";
import { errorMessage } from "../../utils/commonMethod";

const limit = 10;
export const Vehicle = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [openDrawer, setOpenDrawer] = useState({ status: false });
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openUnassignModal, setOpenUnassignModal] = useState(false);
  const [selectUser, setSelectedUser] = useState({});
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({ make: "", model: "", year: "" });

  const handleSearch = (searchText) => {
    setCurrentPage(1);
    setSearchText(searchText);
  };

  const handleGetVehicleData = async () => {
    setLoading(true);
    let res = await getVehicleListApi(currentPage, limit);
    if (res?.status === 200) {
      const { totalcount = 0, vehicleList = [] } = res?.data || {};
      setData(vehicleList);
      setTotalCount(totalcount);
    } else errorMessage(res);
    setLoading(false);
  };

  const openUnassignConfirmModal = (record) => {
    setSelectedUser(record);
    setOpenUnassignModal(true);
  };

  const closeUnassignModal = () => setOpenUnassignModal(false);

  const handleUnassignVehicle = async () => {
    try {
      console.log(selectUser, "SELECTED_VEHICLE");
      const res = await unassignVehicleApi(selectUser?._id);
      if (res?.status === 200) {
        message.success(res?.message || "Vehicle unassigned successfully");
        handleGetVehicleData();
      } else {
        errorMessage(res);
      }
      setOpenUnassignModal(false);
    } catch (error) {
      errorMessage(error);
      setOpenUnassignModal(false);
    }
  };

  const onPageChange = useCallback((page, pageSize) => {
    setCurrentPage(page);
  }, []);

  const handleDrawerOpenClose = (
    type = "add",
    title = "Add Vehicle",
    data = {}
  ) => {
    setOpenDrawer({ status: !openDrawer.status, type, title, data });
  };

  const handleSearchData = async (filterPath) => {
    setLoading(true);
    const res = await searchVehicleApi(
      searchText,
      currentPage,
      limit,
      filterPath
    );
    if (res?.status === 200) {
      const { totalcount = 0, vehicleData = [] } = res?.data || {};
      setTotalCount(totalcount);
      setData(vehicleData);
    } else errorMessage(res);
    setLoading(false);
  };

  useEffect(() => {
    if (searchText || filters.make || filters.model || filters.year) {
      const path = `&make=${filters.make}&model=${filters.model}&year=${filters.year}`;
      handleSearchData(path);
    } else handleGetVehicleData();
  }, [searchText, currentPage, filters]);

  const openDeleteConfirmModal = (record) => {
    setSelectedUser(record);
    setOpenDeleteModal(true);
  };
  
  const handleDeleteConfirm = async () => {
    try {
      if (selectUser?.status) {
        message.error('Cannot delete a vehicle that is currently in use');
        setOpenDeleteModal(false);
        return;
      }
  
      const res = await deleteVehicleApi(selectUser?._id);
      if (res?.status === 200) {
        message.success(res?.message || "Vehicle deleted successfully");
        handleGetVehicleData();
        setCurrentPage(1);
      } else {
        errorMessage(res);
      }
      setOpenDeleteModal(false);
    } catch (error) {
      errorMessage(error);
      setOpenDeleteModal(false);
    }
  };

  const closeOpenDeleteModal = () => setOpenDeleteModal((pre) => !pre);

  const columns = [
    {
      title: "Name",
      dataIndex: "vehicleName",
      sorter: (a, b) => a.vehicleName.localeCompare(b.vehicleName),
      key: "vehicleName",
    },
    {
      title: "Vehicle Number",
      dataIndex: "vehiclesNumber",
      sorter: (a, b) => a.vehiclesNumber.localeCompare(b.vehiclesNumber),
      key: "vehicleNumber",
    },
    {
      title: "Latitude",
      dataIndex: "latitude",
      key: "latitude",
    },
    {
      title: "Longitude",
      dataIndex: "longitude",
      key: "longitude",
    },
    {
      title: "Address",
      dataIndex: "addressLocation",
      key: "addressLocation",
      render: (_, record) => (
        <>
          {record?.addressLocation?.street},{record?.addressLocation?.city},
          {record?.addressLocation?.state},{record?.addressLocation?.country}
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, record) => (
        <>
          {record?.status ? (
            <>
              <USEbutton style={{ background: "red" }}>Inuse</USEbutton>
            </>
          ) : (
            <USEbutton style={{ background: "green" }}>Free</USEbutton>
          )}
        </>
      ),
    },
    {
      title: "Last Update",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (_, record) => {
        const date = new Date(record.updatedAt);
        const formattedDate = new Intl.DateTimeFormat('en-US', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: false,
          timeZone: 'America/New_York'
        }).format(date);
    
        return <>{formattedDate}</>;
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <ActionWrapper>
          {!record.status && (
            <ActionButton 
              isDelete={true}
              onClick={() => openDeleteConfirmModal(record)}
            >
              <DeleteOutlined style={{ fontSize: '16px' }} />
              Delete
            </ActionButton>
          )}
          {record.status && (
            <ActionButton 
              isDelete={false}
              onClick={() => openUnassignConfirmModal(record)}
            >
              <DisconnectOutlined style={{ fontSize: '16px' }} />
              Unassign
            </ActionButton>
          )}
        </ActionWrapper>
      ),
    },
  ];

  const handleSuccess = () => {
    if (searchText) handleSearchData();
    else handleGetVehicleData();
  };

  const handleSelectValue = (type, e) => {
    const value = e !== undefined ? e : "";
    setCurrentPage(1);
    setFilters({ ...filters, [type]: value });
  };

  return (
    <VehicleWrapper>
      <div className="heading-section">Vehicle</div>
      <div className="filters">
        <div className="search-wrapper">
          <SearchField handleSearch={handleSearch} />
        </div>
      </div>
      <div className="filters filter-dropdown">
      </div>
      <TableComponent data={data} columns={columns} loading={loading} />
      <CustomePagination
        current={currentPage}
        total={totalCount}
        onPageChange={onPageChange}
        defaultPageSize={limit}
      />
      {openDrawer?.status && (
        <DrawerComponent
          open={openDrawer?.status}
          handleClose={handleDrawerOpenClose}
          title={openDrawer?.title || "Add Vehicle"}
        >
          <VehicleForm
            type={openDrawer?.type || "add"}
            handleDrawerOpenClose={handleDrawerOpenClose}
            initialValues={openDrawer?.data}
            handleSuccess={handleSuccess}
          />
        </DrawerComponent>
      )}
      {openDeleteModal && (
        <ModalComponent
          openModal={openDeleteModal}
          setOpenModal={closeOpenDeleteModal}
        >
          <ConfirmModal
            subheading="Are you sure you want to delete this vehicle? This action cannot be undone."
            handleConfirm={handleDeleteConfirm}
            handleCancel={closeOpenDeleteModal}
          />
        </ModalComponent>
      )}
      {openUnassignModal && (
        <ModalComponent
          openModal={openUnassignModal}
          setOpenModal={closeUnassignModal}
        >
          <ConfirmModal
            subheading={`Are you sure you want to unassign ${selectUser?.vehicleName}?`}
            handleConfirm={handleUnassignVehicle}
            handleCancel={closeUnassignModal}
            confirmBtnText={"Unassign"}
          />
        </ModalComponent>
      )}
    </VehicleWrapper>
  );
};

const VehicleWrapper = styled(OuterContainer)`
  .filters {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
  }
  .filter-dropdown {
    justify-content: flex-end;
  }

  .search-wrapper {
    flex: 1;
  }
`;

const USEbutton = styled.button`
  font-size: 14px;
  padding: 5px;
  border-radius: 5px;
  border: none;
  color: white;
`;

const ActionWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

const ActionButton = styled.button`
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 6px 12px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  background: white;
  cursor: pointer;
  transition: all 0.3s;
  font-size: 14px;

  &:hover {
    background: ${props => props.isDelete ? '#fff1f0' : '#e6f7ff'};
    border-color: ${props => props.isDelete ? '#ff4d4f' : '#1890ff'};
    color: ${props => props.isDelete ? '#ff4d4f' : '#1890ff'};
  }
`;

export default Vehicle;