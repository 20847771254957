import React, { useState } from "react";
import styled from "styled-components";
import { OuterContainer } from "../style";
import Complete from "../CompletedProject/Complete";
import Rejected from "../Rejectedproject/Rejected";
import { ProjectManagement } from "../project";
import Requests from "../project/newRequest";
import Allproject from "../project/Allproject";

export default function Requestmanagment() {
  const [selecteddropdown, setSelecteddropdown] = useState();

  return (
    <Request>
      <div className="filter-section">
        <div className="tabs-section">
          <button
            className="tab-btn"
            onClick={() => setSelecteddropdown("All")}
            style={{
              color: selecteddropdown === "All" ? "#ff6801" : "black",
              borderBottom:
                selecteddropdown === "All" ? " 2px solid #ff6801" : "",
            }}
          >
            All{" "}
          </button>
          <button
            className="tab-btn"
            onClick={() => setSelecteddropdown("Incoming")}
            style={{
              color: selecteddropdown === "Incoming" ? "#ff6801" : "black",
              borderBottom:
                selecteddropdown === "Incoming" ? " 2px solid #ff6801" : "",
            }}
          >
            Incoming{" "}
          </button>
          <button
            className="tab-btn"
            onClick={() => setSelecteddropdown("Active")}
            style={{
              color: selecteddropdown === "Active" ? "#ff6801" : "black",
              borderBottom:
                selecteddropdown === "Active" ? " 2px solid #ff6801" : "",
            }}
          >
            Active{" "}
          </button>
          <button
            className="tab-btn"
            onClick={() => setSelecteddropdown("Completed")}
            style={{
              color: selecteddropdown === "Completed" ? "#ff6801" : "black",
              borderBottom:
                selecteddropdown === "Completed" ? " 2px solid #ff6801" : "",
            }}
          >
            Completed
          </button>
          <button
            className="tab-btn"
            onClick={() => setSelecteddropdown("Rejected")}
            style={{
              color: selecteddropdown === "Rejected" ? "#ff6801" : "black",
              borderBottom:
                selecteddropdown === "Rejected" ? " 2px solid #ff6801" : "",
            }}
          >
            Rejected{" "}
          </button>
        </div>
      </div>
      {selecteddropdown === "Active" ? (
        <ProjectManagement />
      ) : selecteddropdown === "Completed" ? (
        <Complete />
      ) : selecteddropdown === "Rejected" ? (
        <Rejected />
      ) : selecteddropdown === "Incoming" ? (
        <Requests />
      ) : (
        <Allproject />
      )}
    </Request>
  );
}
const Request = styled(OuterContainer)`
.filter-section{
    display:flex;
    justify-content:space-between;
    align-items:center;
   
  .tab-btn{
    color:black;
    
    font-size:18px;
  }
  .tabs-section{
    display:flex;
    align-items:center;
    gap:10px;
  }

`;
