import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Table, Input, Button, Space, Modal, Form } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { iLovePDFlistpaged, createCustomSigning, deleteSigning } from "../../services/Api/collection";

const DocumentManagementWrapper = styled.div`
  padding: 20px;
`;

const HeadingSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const getAllDocuments = () => {
  return iLovePDFlistpaged();
};

export const DocumentManagement = () => {
  const [documents, setDocuments] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (documents.length === 0) {
      setLoading(true);
    }
    getAllDocuments().then((res) => {
      setLoading(false);
      if (res.data === documents) return;
      setDocuments(res.data);
      console.log(res.data);
    });
  }, []);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          id={`search-input-${dataIndex}`}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    sorter: (a, b) => (a[dataIndex] || '').localeCompare(b[dataIndex] || ''),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(
          () => document.getElementById(`search-input-${dataIndex}`).select(),
          100
        );
      }
    },
  });

  const handleDelete = (token_requester) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this document?',
      content: 'This action cannot be undone.',
      okText: 'Yes, Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        deleteSigning({ signature_token_requester: token_requester })
          .then(() => {
            // Optionally, display a success message
            // Refresh the document list
            getAllDocuments().then((res) => setDocuments(res.data));
          })
          .catch((error) => {
            console.error('Error deleting document:', error);
            // Optionally, display an error message to the user
          });
      },
    });
  };

  const columns = [
    {
      title: 'UUID',
      dataIndex: 'uuid',
      key: 'uuid',
      ...getColumnSearchProps('uuid'),
    },
    {
      title: 'Created Date',
      dataIndex: 'created',
      key: 'created',
      sorter: (a, b) => new Date(a.created) - new Date(b.created),
    },
    {
      title: 'Expire Date',
      dataIndex: 'expires',
      key: 'expires',
      sorter: (a, b) => new Date(a.expires) - new Date(b.expires),
    },
    {
      title: 'Signer 1',
      children: [
        {
          title: 'Name',
          dataIndex: ['signers', 0, 'name'],
          key: 'signer1_name',
          ...getColumnSearchProps(['signers', 0, 'name']),
        },
        {
          title: 'Email',
          dataIndex: ['signers', 0, 'email'],
          key: 'signer1_email',
          ...getColumnSearchProps(['signers', 0, 'email']),
        },
        {
          title: 'Status',
          dataIndex: ['signers', 0, 'status'],
          key: 'signer1_status',
          ...getColumnSearchProps(['signers', 0, 'status']),
        },
      ],
    },
    {
      title: 'Signer 2',
      children: [
        {
          title: 'Name',
          dataIndex: ['signers', 1, 'name'],
          key: 'signer2_name',
          ...getColumnSearchProps(['signers', 1, 'name']),
        },
        {
          title: 'Email',
          dataIndex: ['signers', 1, 'email'],
          key: 'signer2_email',
          ...getColumnSearchProps(['signers', 1, 'email']),
        },
        {
          title: 'Status',
          dataIndex: ['signers', 1, 'status'],
          key: 'signer2_status',
          ...getColumnSearchProps(['signers', 1, 'status']),
        },
      ],
    },
    {
      title: '',
      key: 'action',
      render: (text, record) => (
        <Button
          type="primary"
          danger
          onClick={() => handleDelete(record.token_requester)}
        >
          X
        </Button>
      ),
    },
  ];

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        console.log('Form values:', values);
        setIsModalVisible(false);
        form.resetFields();
        const newDocument = {
          address: values.address,
          companyName: values.companyName,
          adminEmail: values.adminEmail,
          adminName: values.adminName,
          clientName: values.clientName,
          clientEmail: values.clientEmail,
        };
        createCustomSigning(newDocument);
        // Optionally refresh the document list
        // getAllDocuments().then((res) => setDocuments(res.data));
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  return (
    <DocumentManagementWrapper>
      <HeadingSection>
        <p>Document Center</p>
        <Button type="primary" onClick={() => setIsModalVisible(true)}>
          Add Document
        </Button>
      </HeadingSection>
      <Table
        columns={columns}
        dataSource={documents}
        rowKey="uuid"
        loading={loading}
      />

      <Modal
        title="Add Document"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk}>
            Submit
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical" name="add_document_form">
          <Form.Item
            name="address"
            label="Address"
            rules={[{ required: true, message: 'Please input the address!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="companyName"
            label="Company Name"
            rules={[{ required: true, message: 'Please input the company name!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="adminEmail"
            label="Admin Email"
            rules={[
              { required: true, message: 'Please input the admin email!' },
              { type: 'email', message: 'Please enter a valid email!' },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="adminName"
            label="Admin Name"
            rules={[{ required: true, message: 'Please input the admin name!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="clientName"
            label="Client Name"
            rules={[{ required: true, message: 'Please input the client name!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="clientEmail"
            label="Client Email"
            rules={[
              { required: true, message: 'Please input the client email!' },
              { type: 'email', message: 'Please enter a valid email!' },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </DocumentManagementWrapper>
  );
};

export default DocumentManagement;
