/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";

import styled from "styled-components";
import { ActionWrapper, OuterContainer } from "../style";
import TableComponent from "../../components/table";
import CustomePagination from "../../components/table/pagination";
import DrawerComponent from "../../components/drawer";
import SearchField from "../../components/fields/searchField";
import ModalComponent from "../../components/modal";
import { Button } from "antd";

import {
  getTeamMemberApi,
  searchMemberApi,
} from "../../services/Api/collection";
import { errorMessage } from "../../utils/commonMethod";
import TeamMemberForm from "./teamMemberForm";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { refress } from "../../services/redux/Refresstoken";
import { load } from "../../services/redux/Loader";

let limit = 10;
export default function Team() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [openuserModal, setopenuserModal] = useState(false);
  const [record, setrecord] = useState();
  const [openDrawer, setOpenDrawer] = useState({
    status: false,
    type: "",
    title: "",
    data: {},
  });
  const [searchText, setSearchText] = useState("");

  const onPageChange = (page) => {
    setCurrentPage(page);
  };
  const dispatch = useDispatch();
  console.log("hello");
  const refresstoken = useSelector((e) => e?.Refresstoken?.data);
  console.log(data, "dddd");

  const getMemberList = useCallback(async () => {
    setLoading(true);
    dispatch(load(true));
    let res = await getTeamMemberApi(currentPage, limit, refresstoken);
    if (res?.status === 200) {
      const { total_Members = 0 } = res?.data || {};
      setData(res?.data?.getMembersData?.getMembersData);
      dispatch(refress(res?.data?.getMembersData?.refresh_token));
      setTotalCount(total_Members);
      dispatch(load(false));
    } else {
      errorMessage(res);
      setLoading(false);
      dispatch(load(false));
    }
    setLoading(false);
    dispatch(load(false));
  }, [currentPage]);

  const getMemberSearchData = async () => {
    setLoading(true);

    const res = await searchMemberApi(searchText, currentPage, limit);
    if (res?.status === 200) {
      const { locationListing, totalCount } = res?.data || {};
      setData(locationListing);
      setTotalCount(totalCount);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (searchText) getMemberSearchData();
    else getMemberList();
  }, [searchText, currentPage]);

  const columns = [  
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email_address",
      key: "email_address",
    },
    {
      title: "Phone No",
      dataIndex: "mobile_phone",
      key: "mobile_phone",
    },
    {
      title: "Role",
      dataIndex: "job_title",
      key: "job_title",
    },
    {
      title: "User Details",
      key: "action",
      render: (_, record) => (
        <ActionWrapper>
          <Button
            onClick={() => {
              setopenuserModal(true);
              setrecord(record);
              console.log(record);
            }}
          >
            View Details
          </Button>
        </ActionWrapper>
      ),
    },
  ];

  const handleDrawerOpenClose = (type = "", title = "", data = {}) => {
    let initdata;
    if (type === "edit") {
      const { result, __v, ...rest } = data;
      initdata = { ...rest };
    } else initdata = data;
    setOpenDrawer({ status: !openDrawer?.status, type, title, data: initdata }); //(!openDrawer);
  };

  const handleSearch = (searchText) => {
    setCurrentPage(1);
    setSearchText(searchText);
  };

  const closeOpenuserModal = () => {
    setopenuserModal(false);
  };

  return (
    <TeamWrapper>
      <div className="heading-section">Member</div>
      <div className="filters">
        <div className="search-wrapper">
          <SearchField handleSearch={handleSearch} />
        </div>
        {/* <SelectComponent
          handleSelectValue={handleSelectValue}
          placeholder="Projects"
        /> */}
        {/* <ButtonComponent
          text="Add"
          onClick={() => handleDrawerOpenClose("add", "Add Member")}
        /> */}
      </div>
      <TableComponent data={data} columns={columns} loading={loading} />

      <CustomePagination
        current={currentPage}
        total={totalCount}
        onPageChange={onPageChange}
        defaultPageSize={limit}
      />

      {openDrawer?.status && (
        <DrawerComponent
          open={openDrawer?.status}
          handleClose={handleDrawerOpenClose}
          title={openDrawer?.title || "Add Member"}
        >
          <TeamMemberForm
            initMemberList={openDrawer?.data}
            type={openDrawer?.type}
            handleMemberDrawer={handleDrawerOpenClose}
            handleSuccess={getMemberList}
          />
        </DrawerComponent>
      )}

      {openuserModal && (
        <ModalComponent
          openModal={openuserModal}
          setOpenModal={closeOpenuserModal}
        >
          <Detailsmodal>
            <li>
              {" "}
              <span className="highlight">First Name : </span>{" "}
              {record?.first_name ?? "N/A"}
            </li>
            <li>
              <span className="highlight">Last Name : </span>
              {record?.last_name ?? "   N/A"}
            </li>
            <li>
              <span className="highlight"> Business Phone : </span>
              {record?.business_phone ?? "   N/A"}
            </li>
            <li>
              <span className="highlight">city : </span>{" "}
              {record?.city ?? "   N/A"}
            </li>
            <li>
              <span className="highlight">Mobile Phone: </span>{" "}
              {record?.mobile_phone ?? "N/A"}
            </li>
            <li>
              <span className="highlight">email address : </span>{" "}
              {record?.email_address ?? "N/A"}
            </li>
            <li>
              <span className="highlight">procoreUserId: </span>:{" "}
              {record?.procoreUserId ?? "N/A"}
            </li>
            <li>
              <span className="highlight">job_title : </span>{" "}
              {record?.job_title ?? "N/A"}
            </li>
           
            <li>
              <span className="highlight"> comment : </span>
              {record?.comment ?? "   N/A"}
            </li>
            <li>
              <span className="highlight">country_code : </span>{" "}
              {record?.country_code ?? "   N/A"}
            </li>
            <li>
              <span className="highlight"> county :</span>{" "}
              {record?.county ?? "   N/A"}
            </li>
            <li>
              <span className="highlight"> state_code :</span>{" "}
              {record?.state_code ?? "   N/A"}
            </li>
          </Detailsmodal>
        </ModalComponent>
      )}

      {/* {openDeleteModal && (
        <ModalComponent
          openModal={openDeleteModal}
          setOpenModal={closeOpenDeleteModal}
        >
          <ConfirmModal
            handleConfirm={handleDeleteConfirm}
            handleCancel={closeOpenDeleteModal}
          />
        </ModalComponent>
      )} */}
    </TeamWrapper>
  );
}

const TeamWrapper = styled(OuterContainer)`
  .filters {
    display: flex;
    margin-bottom: 15px;
    flex-wrap: wrap;
    gap: 10px;
    .search-wrapper {
      width: 100%;
    }
  }
`;
const Detailsmodal = styled.div`
  li {
    font-size: 14px;
    padding: 2px;
    
  }
  .highlight{
    color:black;
  }
`;
